import React, { Component } from "react";
import { List, Card } from "antd";
import {
  BookOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ContactsOutlined,
  MessageOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const data = [
  {
    title: (
      <span>
        <CalendarOutlined /> Team Calendar
      </span>
    ),
    content:
      "The heart of your team room is the calendar. See who is working on what in multiple views. Additionally use to set up team events and other group activities",
    url: "/dayviewer.com",
  },
  {
    title: (
      <span>
        <MessageOutlined /> Task / Entry Commenting
      </span>
    ),
    content:
      "Being able to comment and discuss on the actual task entry helps focus discussions and reduces the chance of chats going off topic. Think of it like social media commenting but for particular jobs/tasks, notes and all other entry types.",
  },
  {
    title: (
      <span>
        <CheckSquareOutlined /> Team Tasks
      </span>
    ),
    content:
      "Team tasks can be assigned depending on the status of the team member. Tasks are viewable as cards so can be seen clearly and also appear in the calendar and views such as timelines. Tasks have priority and progress attributes.",
  },
  {
    title: (
      <span>
        <BookOutlined /> Team Journal
      </span>
    ),
    content:
      "Team journals can be used as a handy document system that everyone can view. The team journal could also be used as a daily activity log, drafting articles, news and information you wish to share within your team.",
  },
  {
    title: (
      <span>
        <ContactsOutlined /> Team Contacts
      </span>
    ),
    content:
      "Creating a team address book is perfect for client, service or supplier information retrieval central to the team. Create a team room for your sales team to work around and to perfect your sales strategy.",
  },
  {
    title: (
      <span>
        <PlusOutlined /> More to Come
      </span>
    ),
    content:
      "There are multiple enhancements in the works and further business features are planned. All designed to make it easy for your business to improve productivity, management and efficiency. DayViewer essentially becomes another  member of your team!",
  },
];

class FeatureGrid extends Component {
  render() {
    return (
      <Card className="feature-grid-background" bordered={false}>
        <List
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 6,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card
                title={item.title}
                // bordered={false}
                className="feature-grid-cards"
              >
                {item.content}{" "}
                {/* <p>
                  <a href={item.url}>Screencast</a>
                </p> */}
              </Card>
            </List.Item>
          )}
        />
      </Card>
    );
  }
}

export { FeatureGrid };
