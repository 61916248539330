import React, { useState, useRef } from "react";
import { Link } from "gatsby";
import {
  Layout,
  Row,
  Col,
  BackTop,
  Button,
  Popover,
  Divider,
  Modal,
  Image,
} from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import LandingTeamSlider from "../../components/landingPages/landingTeamSlider";
import { FeatureGrid } from "../../components/landingPages/featureGrid";
import { TeamPlanListForPopUp } from "../../components/plans/plans";
import DemoInfo from "./demo-info";

const { Content } = Layout;

const TeamLanding = () => {
  const [videoPaused, setVideoPaused] = useState(true);
  const vidRef = useRef(null);
  //  const vidRef2 = useRef(null);

  const handlePlayVideo = () => {
    if (videoPaused === true) {
      vidRef.current.play();
      setVideoPaused(false);
    } else {
      setVideoPaused(true);
    }
  };

  // const handlePlayVideo2 = () => {
  //   if (videoPaused === true) {
  //     vidRef2.current.play();
  //     setVideoPaused(false);
  //   } else {
  //     setVideoPaused(true);
  //   }
  // };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="DayViewer Team Rooms offers clarity and visualization of tasks and progress - An online team calendar planner system made for clarity, not complexity"
        />
        <title>Online Team Calendar &amp; Task Management</title>
      </Helmet>
      <Layout>
        <CommonHeader />
      </Layout>
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />

        <Content className="homepage-background">
          <Row>
            <div className="homepage-banner">
              <Col lg={12} xs={24}>
                <div className="homepage-jumbotron">
                  <div className="homepage-headlines">
                    {/* <h1>Powerful Team Task Management &amp; Organizer System</h1> */}
                    <h1>
                      Easy to Use Online Team Task Manager, Scheduler &amp;
                      Planner{" "}
                    </h1>
                    <h2>
                      Solid & Reliable All In One Team Calendar, Task Manager,
                      Notes, Contacts and Information Organizer
                    </h2>
                    {/* <h4>
                    DayViewer Team Rooms are a complete, easy to use online
                    calendar planner that helps you manage your schedule, plan
                    tasks and keep information organized with clarity and
                    efficiency.
                  </h4> */}
                    <div style={{ paddingTop: 20 }}>
                      <p>
                        If you work with others in a team, need to set reminders
                        for others, or are responsible for scheduling the tasks
                        within your business - DayViewer is a team calendar
                        planner system that you can rely on.{" "}
                      </p>
                      <div style={{ fontWeight: 600 }}>
                        Working Smart Has Never Been So Easy
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button className="homepage-signup-btn">
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://app.dayviewer.com/auth/signup",
                          "_self"
                        )
                      }
                    >
                      Create Your Online Team Calendar
                    </a>
                  </Button>
                </div>
                <div style={{ textAlign: "center", padding: 20 }}>
                  <h5>
                    14 Days Free Trial -
                    <Popover
                      content={
                        <div style={{ width: "500px" }}>
                          <TeamPlanListForPopUp />
                        </div>
                      }
                      trigger="click"
                      title="Plan Information"
                    >
                      <a href="#"> Pricing </a>
                    </Popover>
                    <span> | </span>
                    {/* <Popover
                        overlayStyle={{ width: 500 }}
                        content={<DemoInfo />}
                        trigger="click"
                      > */}
                    <a href="https://dayv.me/team-board-2" target="_blank">
                      {" "}
                      Demo Videos
                    </a>
                    {/* </Popover> */}
                  </h5>
                </div>
              </Col>
              <Col lg={12} xs={0}>
                {/* <LandingTeamSlider /> */}
                {/* <video
                    preload="none"
                    controls="controls"
                    poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-Team-Ad-Poster.jpg"
                    muted="muted"
                    // autoplay="autoplay"
                    // loop="loop"
                    width="100%"
                    name="DayViewer Online Calendar Planner"
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-Team-Ad-1080.mov"
                  /> */}

                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.webp"
                  ></source>
                  <source
                    type="image/png"
                    srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                  ></source>
                  <Image
                    style={{
                      height: "40vw",
                      objectFit: "none",
                      objectPosition: "30% 23%",
                    }}
                    src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/online-calendar-home.png"
                    alt="Online Calendar"
                    title="Online Calendar"
                  />
                </picture>
              </Col>
            </div>
          </Row>

          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Col lg={12} xs={24}>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {/* <a onClick={() => this.setModal3Visible(true)}>
                      <img
                        // className="centered-and-cropped position-centerquarter-lefttop"
                        // width="1200"
                        // height="600"
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-schedule.png"
                        alt="team labels organizer"
                        title="team labels organizer"
                      />
                    </a>
                    <Modal
                      centered
                      visible={this.state.modal3Visible}
                      onOk={() => this.setModal3Visible(false)}
                      onCancel={() => this.setModal3Visible(false)}
                      width="90%"
                    >
                      <img src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-schedule.png" />
                    </Modal> */}
                  <picture>
                    <source
                      type="image/webp"
                      srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/task-list-planner-home.webp"
                    ></source>
                    <source
                      type="image/png"
                      srcSet="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/task-list-planner-home.png"
                    ></source>
                    <Image
                      src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                      className="homepage-image"
                      width="100%"
                      height="100%"
                      alt="Online Team Task Planner"
                      title="Online Team Task Planner"
                    />
                  </picture>
                </div>
              </Col>
              <Col lg={12} xs={0}>
                <div className="homepage-jumbotron">
                  <div className="homepage-headlines">
                    <h1>Online Team Calendar Planner</h1>
                    <h2>Give Your Team Clarity &amp; Focus</h2>
                    <ul style={{ listStyle: "none", textAlign: "left" }}>
                      <li>
                        <h4>
                          Improve team work by scheduling and planning work more
                          efficiently.{" "}
                        </h4>
                        DayViewer helps visualize your work and time, presenting
                        task and other information at a glance.
                      </li>
                      <li>
                        <h4>
                          See the jobs being worked on, overdue and completed
                          easily.
                        </h4>{" "}
                        Monitor progress and give your team members an
                        opportunity to comment directly on tasks and other
                        entries. Greatly reducing group emails and providing
                        frictionless and focussed communication.
                      </li>
                      <li>
                        <h4>Suitable for any type and size of business.</h4> In
                        fact anyone that requires time and task management in a
                        clear, easy to use &amp; flexible solution. DayViewer is
                        private, secure and always improving.
                      </li>
                    </ul>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Sign Up &amp; Make Your Calendar Today
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5> 
                      </div>*/}
                  </div>
                </div>
              </Col>
            </div>
          </Row>
          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Row>
                <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <video
                      ref={vidRef}
                      onClick={handlePlayVideo}
                      preload="none"
                      controls="controls"
                      poster="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/DayViewer-FastPlanner-Teams-Image.png"
                      // muted="muted"
                      // autoplay="autoplay"
                      // loop="loop"
                      width="100%"
                      name="Online Work Scheduler"
                      src="https://res.cloudinary.com/dayviewer1/video/upload/v1669137012/DV_fast_planner_scheduling-bg-music_q7v3xb.mp4"
                    />
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                    }}
                  >
                    <h2>
                      DayViewer Fast Planner for Efficient Work Scheduling
                    </h2>
                    <div>
                      <p>
                        If you have tasks that are commonly set, it's efficient
                        to create templates. This way, all the main information
                        is pre-filled, meaning that it doesn't need to manually
                        be entered each time.
                      </p>
                      <p>
                        Once the templates are created, simply drag and drop
                        into the day boxes to set them. Our Fast Planner
                        interface can save a lot of time and repetitive
                        scheduling. It also makes it much easier to see what is
                        going on within that week at-a-glance.
                      </p>
                      <p>
                        Use the DayViewer Team Planner system to schedule sales
                        calls or visits, content planning, appointments,
                        meetings, jobs and more. - it's completely flexible for
                        your specific needs.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Team Calendar
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5>
                      </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
          <Divider />
          <Row>
            <div
              className="homepage-banner"
              style={{ textAlign: "left", padding: "5em" }}
            >
              <Row>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <h2>
                      Select By Label - Displays All Labelled Entries In One
                      Place
                    </h2>
                    <div>
                      <p>
                        When you have multiple projects going on, labelling your
                        entries becomes pretty important. Labelling ensures your
                        related entries are tied together to a particular
                        keyword.
                      </p>
                      <p>
                        Labels also improve information recall, visibility and
                        search ability by giving you filtering and color-coding.
                      </p>

                      <p>
                        Our labels section is a special feature that also
                        incorporates a quick filter showing you all the various
                        entries created with a particular label.
                      </p>
                      <p>
                        It is a super convenient way to view all your related
                        tasks and other entries together on one page.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Create Your Team Planner
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5>
                      </div> */}
                  </div>
                </Col>
                {/* <Col lg={12} xs={24}>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <a onClick={() => this.setModal1Visible(true)}>
                      <img
                        // className="centered-and-cropped position-centerquarter-lefttop"
                        // width="1200"
                        // height="600"
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                        alt="team labels organizer"
                        title="team labels organizer"
                      />
                    </a>
                    <Modal
                      centered
                      visible={this.state.modal1Visible}
                      onOk={() => this.setModal1Visible(false)}
                      onCancel={() => this.setModal1Visible(false)}
                      width="90%"
                    >
                      <img src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png" />
                    </Modal>
                  </div>
                </Col> */}
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/screenshot-team-labels.png"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Organizer For Teams"
                        title="Online Organizer For Teams"
                      />
                    </picture>
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row>
                <Col lg={12} xs={24}>
                  <div>
                    <picture>
                      <Image
                        //src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/landing/task-list-planner-home.png"
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-cards-progress.gif"
                        className="homepage-image"
                        width="100%"
                        height="100%"
                        alt="Online Team Task Planner"
                        title="Online Team Task Planner"
                      />
                    </picture>
                  </div>
                </Col>
                <Col lg={12} xs={0}>
                  <div
                    style={{
                      paddingLeft: 20,
                      textAlign: "left",
                    }}
                  >
                    <h2>One-Click Team Task Status Filtering</h2>
                    <div>
                      <p>
                        Often you just want to see how tasks are progressing.
                        The task cards view shows all tasks on one board.
                      </p>
                      <p>
                        At the top of this board is a set of statuses which can
                        be selected. So you can quickly jump through which tasks
                        are at which stage.
                      </p>
                      <p>
                        The cards also will highlight which tasks are overdue
                        automatically.
                      </p>
                      <p>
                        It is good practice for the team members that are
                        assigned tasks to update progress regularly, it assists
                        forward planning and helps the project or entire
                        business stay on track.
                      </p>
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button className="homepage-signup-btn">
                        <a
                          href="#"
                          onClick={() =>
                            window.open(
                              "https://app.dayviewer.com/auth/signup",
                              "_self"
                            )
                          }
                        >
                          Join &amp; Try For Free
                        </a>
                      </Button>
                    </div>
                    {/* <div style={{ textAlign: "center", padding: 20 }}>
                        <h5>
                          14 Days Free Trial -
                          <Popover
                            content={
                              <div style={{ width: "500px" }}>
                                <TeamPlanListForPopUp />
                              </div>
                            }
                            trigger="click"
                            title="Plan Information"
                          >
                            <a href="#"> Pricing </a>
                          </Popover>
                          <span> | </span>
                          <Popover
                            overlayStyle={{ width: 500 }}
                            content={<DemoInfo />}
                            trigger="click"
                          >
                            <a href="#"> Request Demo</a>
                          </Popover>
                        </h5>
                      </div> */}
                  </div>
                </Col>
              </Row>
              {/* <Divider /> */}
              {/* <Row>
                  <Col lg={12} xs={0}>
                    <div
                      style={{
                        textAlign: "left",
                        paddingTop: "100px",
                      }}
                    >
                      <h2>Team Dashboard - At A Glance Overview</h2>
                      <div>
                        <p>
                          Balance out the jobs you need to hand out, especially
                          for similar team members. In this view on your team
                          dashboard, you can see exactly where the tasks are
                          distributed.
                        </p>
                        <p>
                          Used effectively this could help your team manage
                          tasks together as managers can reassign jobs and tasks
                          to meet deadlines and avoid bottlenecks.
                        </p>
                        <p>
                          Your tasks times would also be more accurately set as
                          you would have a better picture of when a particular
                          task would likely be started.
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Button className="homepage-signup-btn">
                          <a
                            href="#"
                            onClick={() =>
                              window.open(
                                "https://app.dayviewer.com/auth/signup",
                                "_self"
                              )
                            }
                          >
                            Sign Up - Free Trial
                          </a>
                        </Button>
                      </div>
                     
                    </div>
                  </Col>
                  <Col lg={12} xs={24}>
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    >
                     
                      <Image
                        src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/landing/dv-team-dash-tasks-2.png"
                        alt="online team task manager dashboard"
                        title="online team task manager dashboard"
                        width="1000"
                        height="600"
                      />
                    </div>
                  </Col>
                </Row> */}
            </div>
          </Row>
          <Row>
            <FeatureGrid />
          </Row>
        </Content>
      </Layout>
      <Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};
// class TeamLanding extends React.Component {
// state = {
//   modal1Visible: false,
//   modal2Visible: false,
//   modal3Visible: false,
// };
// setModal1Visible(modal1Visible) {
//   this.setState({ modal1Visible });
// }

// setModal2Visible(modal2Visible) {
//   this.setState({ modal2Visible });
// }

// setModal3Visible(modal3Visible) {
//   this.setState({ modal3Visible });
// }
// render() {
//   return (

//   );
// }
// }

export default TeamLanding;
